@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,700&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#FCFCFC;
  
}
body::before{
  content: '';
  width: 732px;
  height: 665px;
  background: #E63946;
  position: fixed;
  left: -44px;
  top: -400px;
  opacity: 3%;
  border-radius: 100%;
  z-index: -100;
}
body::after{
  content: '';
  width: 1024px;
  height: 665px;
  background: #1D3557;
  position: fixed;
  right: -520px;
  bottom: -150px;
  opacity: 3%;
  border-radius: 100%;
  z-index: -100;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
fieldset{
  display: inline-block;
  padding: 0;
  border: 0;
  margin: 0 0 35px 0;
  width: 100%;
}
.logo{
  max-width: 285px;
}
.multiBtn{
  max-width: 515px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.help.is-danger{
  max-width: 500px;
  margin: 0px auto 15px auto;
  color: #E63946;
}

@media (max-width:767px){
  body{background: #fff;}
  body::before{display: none;}
  body::after{display: none;}
  fieldset{margin-bottom: 20px;}

  .logo{max-width: 200px;}
  .multiBtn{flex-flow: column;}

}
html,
body,
#root,
.App {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.landingPage {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.landingPage h1 {
  margin-bottom: 0;
}
.landingPage p {
  margin-bottom: 40px;
}
.landingPage .avatar {
  margin: auto;
  cursor: pointer;
}

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 600px !important;
}
.login p {
  line-height: 28px;
  font-weight: normal;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  max-width: 100%;
}

/* --------------------------- Amin */

header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 3px;
}
header .logo,
.mainLogo {
  max-height: 100px;
  max-width: 225px;
}
.mainLogo {
  display: block;
  margin: auto;
}
header .logo .mainLogo {
  margin: 0 30px 0 0;
}
header .views {
  margin-left: auto;
  margin-right: 25px;
}
.avatar {
  max-width: 80px;
  position: relative;
}
.avatar::before {
  content: "";
  width: 12px;
  height: 12px;
  background: #3be609;
  position: absolute;
  border-radius: 100px;
  border: 1px solid #fff;
  bottom: 13px;
  left: 2px;
}
.avatar img{
	border-radius:100px;
  box-shadow:0 0 5px rgba(0,0,0,0.5);
  height: 70px;
  width: 70px;
  object-fit: cover;
}
.avatar svg{
  border-radius:100px;
  box-shadow:0 0 5px rgba(0,0,0,0.5);
  height: 70px;
  width: 70px;
  object-fit: cover;
  background-color: #fff;
}

/* ----- Dropdown ----- */

header .dropdown {
  position: relative;
  cursor:pointer;
}
header .dropdown-content {
  background: #fff;
  padding: 20px 0 15px 0;
  width: 215px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: absolute;
  right: 15px;
  margin-top: 10px;
  z-index: 100;
}
header .dropdown-content:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #fff;
  display: inline-block;
  position: absolute;
  top: -10px;
  right: 20px;
}
header .dropdown-content .head {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ccc;
  align-items: center;
  padding: 0 15px 15px 15px;
  box-sizing: border-box;
}
header .dropdown-content .head .prof {
  line-height: 16px;
  text-align: left;
}
header .dropdown-content .head figure{
	margin: 0 10px 0 0;
	padding: 0;
	max-width: 50px;
}
header .dropdown-content .head figure img{
  border-radius:100px;
  height: 50px;
  width: 80px;
  object-fit: cover;
}
header .dropdown-content .head figure svg{
  border-radius:40px;
  height: 50px;
  width: 50px;
  object-fit: cover;
}
header .dropdown-content .head strong{
	display: block;
	font-size: 16px;
}
header .dropdown-content .head a{
	font-size: 12px;
	color:#7D7D7D;
	text-decoration: none;
}
header .dropdown-content .links{
	display: flex;
	flex-direction: column;
	padding: 10px 0px 0 0px;
}
header .dropdown-content .links a{
	display: flex;
	align-items: center;
	padding: 7px 0;
	color:#11233C;
	text-decoration: none;
	font-size: 12px;
}
header .dropdown-content .links a:last-child{
	border-top:1px solid #ccc;
	padding-top: 15px;
  margin-top: 10px;
  cursor: pointer;
}
header .dropdown-content .links a img {
  max-width: 16px;
  margin-right: 10px;
  margin-left: 20px;
}

@media (max-width: 1280px) {
  header > div {
    width: auto;
  }
  header .headerBtns {
    order: 1;
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  header .headerBtns button {
    width: 49%;
  }
}

@media (max-width: 767px) {
  header .logo {
    max-width: 150px;
    margin-right: 0;
  }
  header .headerBtns {
    flex-flow: column;
    margin-top: 0px;
  }
  header .headerBtns button {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    padding:15px;
  }
  header .avatar {
    max-width: 70px;
    margin: 5px 8px 0 0;
  }
  header .views {
    margin: auto;
  }
  header .views button {
    padding: 10px;
  }
  header .dropdown-content {
    right: -6px;
  }
  header .avatar::before {
    bottom: 4px;
  }
}

/* --------------------------- Amin // END */

a {
  color: black;
  /* background-color: white; */

  text-align: left;
  text-decoration: none;
  display: inline-block;
}
/* a:hover {
  background-color: #f7f7f7;
  border-radius: 10px;
} */

.viewSelect {
  display: inline;
  padding: 10px;
  margin: 5px;
  text-align: center;
  font-size: 10px;
  float: "left";
}
.viewSelect img {
  float: "left";
  padding-right: 10px;
}
.viewdiv {
  display: inline-block;
  margin-top: 40px;
  margin-right: 40px;
}
.headerLeft {
  text-align: right;
  margin: 10px;
}
.updateList {
  font-size: 14;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  line-height: 1.8;
  color: #06940d;
}
.updateList #cross {
  font-size: 14px;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  color: #b33822;
}
.updateList ul {
  font-size: 12px;
  list-style-type: disc;
  margin-left: -25px;
}

.backupList {
  list-style-type: none;
  font-size: 10px;
  padding: 0px;
  margin: 0px;
  line-height: 1.5;
}
.tablelist {
  font-size: 10px;
  display: grid;
  width: 112%;
  position: relative;
  text-align: center;
  margin: 12px -14px;
}
.tablelist tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.tablelist td {
  width: 110px;
}

.companyListing {
  /* box-shadow: 0 0 2px #f8f8f8; */
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.companyListing.listView {
  flex-flow: column;
}
.companyListing.listView .item {
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  display: flex;
  align-items: center;
  padding: 13px;
  border-radius: 20px;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.companyListing.listView .item .header {
  max-width: 60px;
  margin-right: 20px;
  height: 60px;
}
.companyListing.listView .item .description {
  display: flex;
  padding: 0;
}
.companyListing.listView .item .description h3 {
  width: 28%;
}
.companyListing.listView .item .description span,
.companyListing.listView .item .description p {
  margin: 0 10px;
  line-height: 24px;
  width: 20%;
}
.companyListing.listView .item .footer {
  position: relative;
  width: auto;
  margin-left: auto;
  margin-bottom: 0;
  background: #f1f1f1;
  padding: 0 10px;
  border-radius: 10px;
}

.companyListing .item {
  box-sizing: border-box;
  box-shadow: 0 0 4px 0px #bfbfbf;
  border-radius: 30px;
  margin: 15px;
  overflow: hidden;
  display: block;
  text-align: left;
  padding: 25px;
  position: relative;
  width: calc(25% - 30px);
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.companyListing .item .header {
  width: 100%;
  height: 160px;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
}
.companyListing .item .description h3 {
  display: table-caption;
  font-size: 24px;
  padding: 0;
  margin: 0;
  font-weight: 500;
  color: #1d3557;
}
.companyListing .item .header img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.companyListing .item .description {
  padding: 30px 0;
}

.companyListing .item .description p {
  font-size: 12px;
  color: #a7a7a7;
  padding: 0;
  margin: 0;
}

.companyListing .item .description .listing {
  font-size: 12px;
  color: #a7a7a7;
  display: block;
  margin-top: 16px;
}
.companyListing .item .description .improvelisting {
  font-size: 12px;
  color: #e63946;
  display: block;
  margin-top: 5px;
}

.searchFilter {
  padding: 30px 10px;

  float: right;
}
.searchFilter input {
  border-radius: 50px;
  padding: 10px;
  border: 2px solid #ccc;
  font-size: 14px;
  outline: none;
  width: 280px;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 6px 10px -3px #ddd;
  border-width: thin;
}

.reportWrapper {
  /* display: grid; */
  text-align: left;
  padding: 0px 0px;
  position: relative;
  width:100%;
}
.reportWrapper button{
  margin:0px;
}
.sidebarUl {
  text-align: left;
  padding: 0;
  display: block;
  margin-top: 60px;
  /* width: 20%;
  float: left; */
}
.sidebarUl li {
  list-style: none;
  border-left: 5px solid #f5f8fe;
  padding: 18px 0px;
}
.sidebarUl svg {
  float: left;
  width: 50px;
  height: 50px;
  padding-right: 30px;
}
.sidebarUl svg path {
  fill: #1d3557;
}
.sidebarUl a {
  text-decoration: none;
  padding: 0px 0px 0px 50px;
  width: 100%;
  box-sizing: border-box;
}
.sidebarUl a span {
  font-size: 16px;
  max-width: 132px;
  float: left;
  text-transform: capitalize;
  line-height: 25px;
  font-weight: 500;
  color: #1d3557;
}
.sidebarUl a img {
  padding-right: 15px;
  float: left;
}
.sidebarUl li.active a span {
  color: #e63946;
}
.sidebarUl li.active a svg path {
  fill: #e63946;
}
.sidebarUl li.active {
  border-left: 5px solid #e63946;
}

.reportToggle h1 {
  cursor: pointer;
}
.reportToggle h1:before {
  width: 29px;
  content: "+";
  border: 2px solid;
  display: block;
  text-align: center;
  float: left;
  margin-right: 13px;
  border-radius: 4px;
}
.reportToggle.active h1:before {
  content: "-";
}
.reportToggle.active h1 {
  cursor: pointer;
  color: #44c4a1;
  font-weight: 600;
}
/* .reportToggle > div {
  display:none
}
.reportToggle.active > div {
  display:block
} */

.leftSidebar {
  float: left;
  background: #f5f8fe;
  max-width: 26%;
  width: 100%;
  border-radius: 0 0 50px 0;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.2);
}
.leftSidebar .sidebarLogo .mainLogo {
  max-height: 110px;
  max-width: 320px;
  margin-left: 5px;
}
.contentArea {
  width: 100%;
  float: left;
  max-width: 73.9%;
  padding: 35px;
  box-sizing: border-box;
}

.sidebarLogo img {
  height: auto;
  padding: 35px 0px;
  width: 80%;
  display: table;
  margin: 0 auto;
  margin-bottom: 35px;
}
.contentArea .headerLeft {
  margin: 25px 15px;
  min-height: 155px;
}

.sidebarHeader .logo {
  display: none;
}

.reportToggle h1 {
  font-size: 24px !important;
}
.companyListing .item .arrowDropdown {
  display: none;
}
.mbArrow a {
  padding: 15px;
  padding-left: 5px;
  color: #44c4a1;
  font-size: 11px;
  font-weight: 500;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
  display: none;
}

.reportToggle .Rep {
  height: 0;
  overflow: hidden;
}

.reportToggle.active .Rep {
  height: auto;
  position: relative;
  -webkit-animation: mymove 300ms;
          animation: mymove 300ms;
  margin-left: -10px;
}

.sidebarUl li.active a {
  position: relative;
  -webkit-animation: colorMove 500ms;
          animation: colorMove 500ms;
}

@-webkit-keyframes colorMove {
  0% {
    color: #000;
  }
  50% {
    color: rgb(87, 87, 87);
  }
  100% {
    color: red;
  }
}

@keyframes colorMove {
  0% {
    color: #000;
  }
  50% {
    color: rgb(87, 87, 87);
  }
  100% {
    color: red;
  }
}

.sidebarUl li.active {
  position: relative;
  -webkit-animation: borderMove 500ms;
          animation: borderMove 500ms;
}

@-webkit-keyframes borderMove {
  0% {
    border-color: #000;
  }
  50% {
    border-color: rgb(87, 87, 87);
  }
  100% {
    border-color: red;
  }
}

@keyframes borderMove {
  0% {
    border-color: #000;
  }
  50% {
    border-color: rgb(87, 87, 87);
  }
  100% {
    border-color: red;
  }
}
.sidebarUl li.active a svg path {
  position: relative;
  -webkit-animation: svgMove 500ms;
          animation: svgMove 500ms;
}

@-webkit-keyframes svgMove {
  0% {
    fill: #000;
  }
  50% {
    fill: rgb(87, 87, 87);
  }
  100% {
    fill: red;
  }
}

@keyframes svgMove {
  0% {
    fill: #000;
  }
  50% {
    fill: rgb(87, 87, 87);
  }
  100% {
    fill: red;
  }
}
.reportToggle h1 {
  margin: 20px 0 15px 0 !important;
}

.itemCard {
  width: 18%;
  position: relative;
  margin: 1%;
}

.reportBoxDiv > a {
  width: 100%;
  cursor: pointer;
  display: block;
}

.mobileToggle {
  width: 18px;
  height: 16px;
  display: none;
  float: left;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 10px;
  margin: 0px 5px;
  position: relative;
}
.mobileToggle span {
  height: 1.5px;
  background: #1d3557;
  border-radius: 100px;
  width: 100%;
}
.mobileToggle.active span:first-child,
.mobileToggle.active span:last-child {
  transform: rotate(45deg);
  margin-top: 8px;
  position: absolute;
  width: 20px;
  background: #e63946;
}
.mobileToggle.active span:last-child {
  transform: rotate(-45deg);
}
.mobileToggle.active span:nth-child(2) {
  display: none;
}

@media (max-width: 1024px) {
  body {
    background: #fff;
  }
  body:before,
  body:after {
    display: none;
  }
  .login,
  .mainContainer {
    box-shadow: none !important;
  }

  .headerLeft .headerButton {
    float: left !important;
    width: 100% !important;
    margin: 11px 0 !important;
  }
  .headerLeft .headerButton button {
    width: 48% !important;
  }
  /* .companyListing{
    display: block !important;
  }*/
  .companyListing .item {
    width: calc(50% - 30px);
  }
  .companyListing.listView .item .description h3 {
    padding-right: 25px;
    font-size: 16px;
  }
  .companyListing.listView .item .description {
    align-items: center;
  }
  .mb_display {
    display: block !important;
  }
  .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .leftSidebar {
    display: none;
    max-width: 100%;
    border-radius: 0;
    position: absolute;
    z-index: 100;
    top: 125px;
    box-shadow: none;
  }
  .leftSidebar.open {
    display: block;
    padding-top: 10px;
  }
  .leftSidebar .sidebarLogo {
    display: none;
  }
  .sidebarUl {
    margin-top: 0;
  }
  .sidebarUl li {
    padding: 15px 0;
  }
  .sidebarUl a {
    padding-left: 20px;
  }
  .sidebarUl svg {
    width: 40px;
    height: 40px;
    padding-right: 15px;
  }
  .sidebarUl a span {
    min-width: 72%;
    padding-right: 20px;
    text-transform: uppercase;
  }

  .contentArea {
    max-width: 100% !important;
    padding: 0px 20px;
  }

  .sidebarHeader .logo {
    display: block;
  }

  .mobileToggle {
    display: flex;
    padding: 1px;
    margin: 5px 60px;
  }
  .itemCard{
    width: 48%;
    float: left;
    min-height: 350px;
  }
}
@media (max-width: 767px) {
  .companyListing .item {
    width: 96% !important;
    box-sizing: border-box !important;
    margin: 2% !important;
    margin-bottom: 20px !important;
  }

  .companyListing .item .arrowDropdown {
    display: block;
    text-align: right;
    margin-top: -15px;
    margin-bottom: 5px;
  }
  .headerLeft .headerButton button {
    width: 100% !important;
    margin: 5px 0 !important;
  }
  .companyListing .item.active {
    padding: 15px 10px;
    border-radius: 17px;
    position: relative;
  }
  .companyListing .item.active .header {
    float: left;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    margin-right: 14px;
  }

  .companyListing .item.active .description {
    float: left;
    width: calc(66%);
    padding: 0;
  }

  .companyListing .item.active .description span.listing {
    display: none;
  }

  .companyListing .item.active .description span.improvelisting {
    display: none;
  }

  .companyListing .item.active .arrowDropdown {
    position: absolute;
    right: 13px;
  }
  .mbArrow a {
    display: block;
    padding-bottom: 0;
  }
  .companyListing .item.active .footer {
    display: none;
  }
  .detailBar .logo {
    display: table;
    margin: 0 auto;
  }

  .detailBar .name {
    width: 100% !important;
    text-align: center;
    padding: 15px 0 !important;
  }
  .detailBar .websitePercentage {
    width: 50% !important;
    padding: 0 !important;
    display: none !important;
    float: right !important;
    padding-left: 10px !important;
    box-sizing: border-box;
    border: 0 !important;
  }

  .detailBar .websitePercentage h3 {
    font-size: 52px !important;
  }

  .detailBar .websitePercentage p {
    font-size: 12px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .detailBar .websiteCount {
    width: 50% !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    padding-left: 10px !important;
    border: 0 !important;
    border-right: 1px solid #ddd !important;
  }

  .detailBar .websiteCount p {
    font-size: 12px !important;
    margin: 16px 0 !important;
  }

  .detailBar .websiteCount p.improve {
    font-size: 16px !important;
  }

  .search.searchFilter {
    float: none !important;
    box-sizing: border-box !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
  }

  .search.searchFilter input[type="text"] {
    width: 100% !important;
    box-sizing: border-box !important;
  }
  .listingMobile p {
    padding: 0px !important;
  }
  /* .listingMobile td img {
    padding-left:0px;
    width: 25px !important;
    height: 25px !important;
  } */
  .listingMobile td a {
    float: right !important;
  }
  .mb_display {
    display: block !important;
  }
  .login {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  .contentArea {
    max-width: 100% !important;
    padding: 0px 20px;
  }
  .reportToggle.active .Rep {
    margin-left: 0;
  }

  .leftSidebar {
    top: 63px;
  }

  .logBar{
    flex-wrap: wrap;
  }
  .itemCard{
    width: 97%;
    margin:10px auto 20px;
    float: none;
  }
}
/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

.react-calendar-heatmap text {
  font-size: 7px;
  fill: #888;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
  fill: #8cc665;
}

/*
 * Github color scale
 */

.react-calendar-heatmap .color-github-0 {
  fill: #eeeeee;
}
.react-calendar-heatmap .color-github-1 {
  fill: #d6e685;
}
.react-calendar-heatmap .color-github-2 {
  fill: #8cc665;
}
.react-calendar-heatmap .color-github-3 {
  fill: #44a340;
}
.react-calendar-heatmap .color-github-4 {
  fill: #1e6823;
}

/*
 * Gitlab color scale
 */

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-gitlab-1 {
  fill: #acd5f2;
}
.react-calendar-heatmap .color-gitlab-2 {
  fill: #7fa8d1;
}
.react-calendar-heatmap .color-gitlab-3 {
  fill: #49729b;
}
.react-calendar-heatmap .color-gitlab-4 {
  fill: #254e77;
}

.uptimeMonitoring h2{
  margin-top:40px;
}
.uptimeMonitoringWidget{
  padding:0px;
  margin:0px;
}

.uptimeMonitoringWidget li {
  list-style: none;
  clear: both;
  padding: 18px 1px;
}

.uptimeMonitoringWidget p {
  font-weight: bold;
  float: left;
  padding: 0;
  margin: 0;
}

.uptimeMonitoringWidget span {
  float: right;
}

.uptimeMonitoringWidget span.up {
  color: green;
  font-weight: bold;
}

.uptimeMonitoringWidget span.down {
  color: red;
  font-weight: bold;
}


ul.uptimeMonitoringDetails {
    background: #f5f8fe;
    max-width: 100%;
    box-shadow: 0 0px 12px rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
    padding: 20px;
    clear: both;
    line-height: 28px;
}

ul.uptimeMonitoringDetails li {
    list-style: none;
    float: left;
    width: 25%;
}

ul.uptimeMonitoringDetails li p {
    padding: 0;
    margin: 0;
    font-size: 13px;
}

ul.uptimeMonitoringDetails:before,ul.uptimeMonitoringDetails:after {
    clear: both;
    display: block;
    content: '';
}

ul.uptimeMonitoringDetails  span {
    font-size: 29px;
    font-weight: bold;
}

.up {color: green;}
.down {color: #df484a;}

.logBar {
  clear: both;
  display: flex;
  width: 100%;
}

.logBar .bars {
  background: #ccc;
  margin: 2px;
  height: 31px;
  text-align: center;
  border-radius: 5px;
  border: 0;
  width: 7px;
}
.logBar .bars em {
  display: none;
}
.logBar:before,.logBar:after {content: '';display: block;clear: both;}

.tooltip {
  position: absolute;
  background: #000;
  display: none;
  color: #fff;
  border-radius: 4px;
  width: 152px;
  text-align: left;
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  margin-top:  /*-36px;*/-64px;
  margin-left: /*-20px;*/-97px;
}

.bars:hover .tooltip {
  display: block;
  cursor: pointer;
}

.tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 67%/*50%*/;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.bars:nth-child(-n+7) .tooltip {
  margin-left: -20px;
  
}
.bars:nth-child(-n+7) .tooltip::after {
  left: 16%;
  margin-left: -5px;
  border-width: 5px;
}

.bars:nth-last-child(-n+7) .tooltip {
  margin-left: -130px;
  
}
.bars:nth-last-child(-n+7) .tooltip::after {
  left: 87%;
  margin-left: -5px;
  border-width: 5px;
}

.logBar .bars.up {
  background: green;
}

.logEvent {
  border-bottom: 1px solid #ccc;
  padding: 40px 20px 40px 45px;
  box-sizing: border-box;
}

.logEvent:before,.logEvent:after {content: '';display: block;clear: both;}

.logEvent h3 {
  font-size: 24px;
  padding: 0;
  margin: 0;
  margin-bottom: 13px;
  color: #131a26;
}

.logEvent 
.logEventDate {
  background: #e6e7e8;
  display: inline;
  display: initial;
  padding: 5px 14px;
  border-radius: 3px;
  color: #687790;
}

.logEvent p {
  color: #687790;
}

.logEvent p badge {
  background: #ccc;
  padding: 2px 8px;
  border-radius: 1px;
}

.red badge {
  background: #df484a !important;
  color: #fff;
}

.logEvent h3 svg {
  width: 29px;
  position: absolute;
  margin-left: -39px;
}
.green svg{
  stroke: green;
}
.red svg{
  transform: rotate(180deg);
  stroke: #df484a;
}

.logBar .bars[status="up"] {
  background: green;
}

.logBar .bars[status="down"] {
  background: red;
}

select.monitorOptions {
  font-size: 16px;
  color: #1D3557;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: block;
  border: 1px solid #CBCBCB;
  padding: 8px 28px 8px 5px;
  margin-top: 10px;
  margin-left: auto;
  position: absolute;
  right: 0;
  z-index: 99;
}

.admin {
  text-align: left;
  padding: 25px 50px;
}
.admin h1{
  margin: 22px 0px 40px 20px;
}
.admin .det {
  padding: 0 20px;
}

.admin .det .det_list p {
  font-weight: bold;
}

.admin .det .det_list p span {
  font-weight: normal;
}

.admin .det .det_list {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.admin .det ul {
  padding: 0;
  margin: 0;
}

.admin .det > ul > li {
  list-style: none;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  clear: both;
  padding-bottom: 20px;
}

.admin .det > ul > li > ul {
  width: 100%;
  padding: 0 30px;
  clear: both;
}

.admin .det > ul > li > ul > li {
  font-weight: normal;
  font-size: 16px;
  list-style: none;
}

.admin .det > ul > li > ul > li p {
  float: left;
  width: 25%;
}

.reportList {
  display: inline-flex;
  font-size: 18px;
  border-style: solid;
  border-color: #d6d6d6;
  border-radius: 10px;
  border-width: thin;
  width: calc(25% - 1%);
  margin: 0.5%;
  height: 64px;
  box-shadow: 2px 2px #dddddd;
  box-sizing: border-box;
  position: relative;
}

.admin .det > ul > li > ul > li h3:before {
  width: 29px;
  content: "+";
  border: 2px solid;
  display: block;
  text-align: center;
  float: left;
  margin-right: 13px;
  border-radius: 4px;
}

.reportList a {
  width: 100%;
  cursor: pointer;
  display: block;
}

.reportList span.month {
  color: #1d3557;
  font-weight: 500;
  font-size: 18px;
  float: left;
  line-height: 23px;
  /* padding: 10px; */
}

.reportList .link {
  color: #e63946;
  font-size: 10px;
  font-weight: bold;
  height: 13px;
  width: 113px;
  display: block;
  text-transform: uppercase;
}

.reportList span.datetime {
  color: #a7a7a7;
  font-size: 12px;
  position: relative;
  top: 5px;
  float: left;
}

.reportList .pdf {
  float: right;
}

.reportList .pdf svg {
  width: 37px;
  height: 44px;
  position: relative;
  top: 8px;
  right: 10px;
}

.reportList span {
  padding: 10px 0px 0px 10px;
}

.reportList.addMore:before {
    width: 100%;
    content: "+";
    display: block;
    text-align: center;
    font-size: 48px;
    color: #b5b5b5;
    cursor: pointer;
}
.removePdf {
  position: absolute;
  background: rgb(228 228 228 / 89%);
  width: 100%;
  border-radius: 9px;
  display:none;
  cursor:pointer;
}
.reportList:hover .removePdf{
  display:block;
}
.removePdf:before {
  width: 100%;
  content: "+";
  display: block;
  text-align: center;
  font-size: 50px;
  color: #b5b5b5;
  cursor: pointer;
  transform: rotate(45deg);
}


#upload-event {
  position: relative;
  padding: 50px 30px;
  height: auto;
  border: 2px gainsboro dashed;
  background: rgb(238 238 238 / 39%);
  color:#848484;
  border-radius: 20px;
  outline: none;
  text-align: center;
  box-sizing: border-box;
  margin: 33px;
  display: block;
}
#upload-event.dragged-over {
  border: 4px solid gray;
  color:#333;
}

.alrtButn button{
  margin:0 auto !important;
  margin-top:20px !important;
}

.loader svg path {
  fill: #ca2626;
}

.loader svg {
  height: 100px;
  padding-top: 30%;
}

.loader {
  background: rgb(255 255 255 / 100%);
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  text-align: center;
}

@media (min-width:1024px) and (max-width:1366px)
{
  .uptimeMonitoring{
    box-sizing: border-box;
    width: 100%;

  }

  .contentArea .sidebarHeader .headerBtns {
    order: 1;
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .contentArea .sidebarHeader .headerBtns button {
    width: 49%;
    padding:15px;
  }
  
  .companyListing .item{
    width: calc(33.33% - 30px);
  }

  .mb_display {
    display: block !important;
  }

  .itemCard{
    width: calc(24% - 13px);
    float: left;
    min-height: 350px
  }
}
.firewallMain {
  text-align: center;
}
.firewallMain svg{
  width: 100%; 
  height: 130px;
  margin-bottom: 5px;
}
/*for header with sidebar*/
@media (min-width:1367px) and (max-width:1474px)
{
  .contentArea .sidebarHeader .headerBtns {
    order: 1;
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .contentArea .sidebarHeader .headerBtns button {
    width: 49%;
    padding:15px;
  }
}
.multiBtn .single{
  max-width: 100%;
}


.ticketToggle h1 {
  cursor: pointer;
}
.ticketToggle h1:after {
  width: 29px;
  content: "+";
  border: 2px solid;
  display: block;
  text-align: center;
  float: left;
  margin-right: 13px;
  border-radius: 4px;
}
.ticketToggle.active h1:after {
  content: "-";
}
.ticketToggle.active h1 {
  cursor: pointer;
  color: #44c4a1;
  font-weight: 600;
}

.ticketToggle textarea {
  border-radius: 10px;
  outline: none;
  padding:10px;
  border: 2px solid #ddd;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 6px 10px -3px #ddd;
  line-height: 25px;
  font-size: 16px;
  margin: 10px 0;
  width:90%;
}

.uploadforticket{
  width:98%;
  margin-bottom:25px;
}
.uploadforticket input{
  cursor: pointer;
  padding: 12px 20px;
  outline: 0px;
  width: auto;
  border-radius: 10px;
  border: 2px solid #ddd;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 6px 10px -3px #ddd;
  font-size: 16px;
  margin-right: 10px;
} 
.ticketToggle h1 {
  margin: 20px 0 15px 0 !important;
}

.ticketToggle .Tick {
  height: 0;
  overflow: hidden;
}

.ticketToggle.active .Tick {
  height: auto;
  position: relative;
}

