@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,700&display=swap');
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#FCFCFC;
  
}
body::before{
  content: '';
  width: 732px;
  height: 665px;
  background: #E63946;
  position: fixed;
  left: -44px;
  top: -400px;
  opacity: 3%;
  border-radius: 100%;
  z-index: -100;
}
body::after{
  content: '';
  width: 1024px;
  height: 665px;
  background: #1D3557;
  position: fixed;
  right: -520px;
  bottom: -150px;
  opacity: 3%;
  border-radius: 100%;
  z-index: -100;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
fieldset{
  display: inline-block;
  padding: 0;
  border: 0;
  margin: 0 0 35px 0;
  width: 100%;
}
.logo{
  max-width: 285px;
}
.multiBtn{
  max-width: 515px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.help.is-danger{
  max-width: 500px;
  margin: 0px auto 15px auto;
  color: #E63946;
}

@media (max-width:767px){
  body{background: #fff;}
  body::before{display: none;}
  body::after{display: none;}
  fieldset{margin-bottom: 20px;}

  .logo{max-width: 200px;}
  .multiBtn{flex-flow: column;}

}